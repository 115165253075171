import React from "react"
import "../../styles/bnpl.scss"
import Layout from "../../components/Layout/Layout"
import Seo from "../../components/Seo/Seo"
import MainSection from "../../components/MainSection/MainSection"
import Section from "../../components/Section/Section"
import Columns from "../../components/Columns/Columns"
import { Link } from "gatsby"
import TextWithIcon from "../../components/TextWithIcon/TextWithIcon"
import heart from "../../svg/Create.svg"
import prevent from "../../svg/Prevent.svg"
import block from "../../svg/Augment.svg"
import compass from "../../svg/ThumbsUp.svg"
import narrow from "../../svg/Narrow.svg"
import identify from "../../svg/MagnifyingGlass.svg"
import QuoteSection from "../../components/QuoteSection/QuoteSection"
import Button from "../../components/Button/Button"
import Documents from "../../images/ilustrations/Documents.png"
import BnplTheme from "../../images/ilustrations/BnplTheme.svg"
import Card from "../../components/Card/Card"

const Bnpl = () => {
  return (
    <Layout>
      <Seo
        title="Stop Payment Fraud & Account Takeovers in BNPL"
        description="Prevent account takeovers, block stolen identiies and novel fraud attempts, and remove friction from your customer journeys to drive growth."
      />

     <MainSection
       caption="BNPL & Payment Fraud"
       title="Buy-Now-Pay-Later & "
       titleHighlight="Payment Fraud"
       text="Resistant AI is designed with BNPL providers in mind."
       className="aml__main"
       divided
       imageLink={BnplTheme}
       button={{
         link: "/contact",
         variant: "blue",
         text: "Get Started",
         size: "large",
       }}
     ></MainSection>

      <Section id="bnpl__citations" className="bnpl__citations">

        <div className="w-wrap">

            <div className="w2-3">
              <h2>BNPL <span className="highlighted">Challenges</span></h2>
              <p>To build market share in the on-demand economy, BNPL providers are
              looking to balance security with accessibility, making their
              customers’ experience as safe as possible with the minimum of
              friction.</p>
            </div>

            <div className="w1-3 bnpl__card">
              <h3>Stolen Identities</h3>
              <p>
              Accurately detecting real vs. stolen identities is notoriously
              difficult and will lead to losses projected at{" "}
              <a
                href="https://www.juniperresearch.com/press/losses-from-online-payment-fraud-more-double-2023"
                target="_blank"
              >
                $48 billion by 2023.
              </a>
              </p>
            </div>

          </div>

          <div className="w-wrap">

            <div className="w2-3 bnpl__card">
            <h3>False Declines</h3>
              <p>
              Being too risk averse is undesirable. Around{" "}
              <a
                href="https://www.merchantfraudjournal.com/false-declines-costing-business-more-realize"
                target="_blank"
              >
                40% of consumers say
              </a> they won’t shop again with a merchant who incorrectly rejects
              their order. With investigating false alerts, staff morale also
              suffers. Engaged and committed employees{" "}
              <a
                href="https://www.stcloudstate.edu/humanresources/_files/documents/supv-brown-bag/employee-engagement.pdf"
                target="_blank"
              >
                perform 20% better and are 87% more likely to stay.
              </a>
              </p>
            </div>

            <div className="w1-3 bnpl__card">
              <h3>Account Takeovers (ATO)</h3>
              <p>
              In 2019,{" "}
              <a
                href="https://www.javelinstrategy.com/coverage-area/2020-identity-fraud-study-genesis-identity-fraud-crisis"
                target="_blank"
              >
                ATO caused losses of $6.8 billion.
              </a>{" "}
              This increased{" "}
              <a
                href="http://pages.sift.com/rs/526-PCC-974/images/ebook-digital-trust-and-safety-q32020-account-takeover-fraud.pdf"
                target="_blank"
              >
                200% in the following year
              </a>{" "}
              where{" "}
              <a
                href="http://kfp.kaspersky.com/wp-content/uploads/2021/01/Kaspersky-Fraud-Report-English-2020.pdf"
                target="_blank"
              >
                50% of fraudulent transactions
              </a>{" "}
              in the finance industry were from account takeovers.
              </p>
            </div>

          </div>

      </Section>

      <Section sectionClass="aml__principles" gray>
          <div className="w-wrap wHeadRow">
            <div className="w1-3">
              <h2>
                <span className="highlighted">Risk</span> Multipliers
              </h2>
            </div>
            <div className="w2-3">
              <p>
              Fraud harms you in more ways than one - it increases your operational costs, exposes you to regulatory risks but first and foremost, it damages your reputation with customers and the financial markets alike.
              </p>
            </div>
          </div>
      </Section>

      <Section>
          <div className="w-wrap wHeadRow">
            <div className="w1-3">
              <h2>
                Why <span className="highlighted">Resistant Transactions</span>
              </h2>
            </div>
            <div className="w2-3">
              <div>
                <p>
                  We started in the BNPL sector and continue to specialize in fraud prevention. We’re committed to protecting convenient customer onboarding with artificial intelligence by replacing extra and unnecessary verification steps.
                </p>
                <p>
                  We empower our customer’s fraud teams to:
                </p>
              </div>
            </div>
          </div>

        <div className="aml__reason w-wrap">

            <div className="w1-3">
              <h3><img alt="Create" src={heart}/>Create</h3>
              <p>
                a safe and trusted customer experience, improving your company’s
                reputation, attracting new clients and new inward investment.
              </p>
            </div>

            <div className="w1-3">
              <h3><img alt="Identify and block" src={identify}/>Identify and Block</h3>
              <p>
                stolen identities by using AI to connect patterns in data that
                might otherwise seem to be unrelated.
              </p>
            </div>

            <div className="w1-3">
              <h3><img alt="Prevent" src={prevent}/>Prevent</h3>
              <p>
                account takeovers by recognising unusual customer behaviour.
              </p>
              </div>
              </div>

            <div className="aml__reason w-wrap">

            <div className="w1-3">
              <h3><img alt="Block" src={block}/>Block</h3>
              <p>
                novel and previously unknown fraud attempts using ensembles of
                Resistant AI ‘Detectors’.
              </p>
            </div>

            <div className="w1-3">
              <h3><img alt="Be confident" src={compass}/>Be Confident</h3>
              <p>
                in exploring new areas of revenue generation/growth, knowing
                that risk is being mitigated.
              </p>
            </div>

            <div className="w1-3">
              <h3><img alt="Narrow the focus" src={narrow}/>Narrow the Focus</h3>
              <p>
                of investigations by prioritising and fully contextualising
                alerts, simplifying analysis and improving the working lives of
                analysts.
              </p>
            </div>

        </div>
      </Section>

      <Section sectionClass="aml__check patternedGray">
        <div className="w-wrap wHeadRow">
          <div className="w1-2">
            <h2><span className="highlighted">Check out</span> our AML Optimization</h2>
          </div>
          <div className="w1-2">
            <Card
                  title="AML Optimization"
                  svgImage={Documents}
                  button={{
                    text: "See details",
                    variant: "blue",
                    link: "/use-cases/aml",
                  }}
            ></Card>
          </div>
        </div>
      </Section>

      <QuoteSection
        className="Homepage__quote"
        quotes={[
          {
            restOfQuote:
              "Resistant AI dramatically reduces false positives and detects advanced fraud and manipulation at the same time. Under the protection of Resistant AI, our Nikita engine can fully focus on credit risk assessment excellence.",
            quoteBy: "Michal Krocil",
            companyName: "Twisto",
            position: "Chief Risk Officer",
            link: "https://www.twisto.cz/",
          },
          {
            restOfQuote:
              "We use Resistant AI’s technology in our KYC process. We greatly value the insights gained to help us validate the authenticity of documents submitted by customers.",
            quoteBy: "Keren Levy",
            companyName: "Payoneer",
            position: "COO",
            link: "https://www.payoneer.com",
          },
          {
            restOfQuote:
              "Resistant AI gives us reliable, real-time insights into our customer data. With high degrees of precision and accuracy.",
            quoteBy: "Dan Hagerty",
            companyName: "Habito",
            position: "CEO - Founder",
            link: "https://www.habito.com/",
          },
          {
            restOfQuote:
              "Resistant AI prevents the manipulation of invoices submitted to our marketplace. It allows our investors to trade in security and saves my team a huge amount of manual work.",
            quoteBy: "Alexandra Belkova",
            companyName: "Roger",
            position: "Head of Operations",
            link: "https://www.roger.cz",
          },
        ]}
      ></QuoteSection>

    </Layout>
  )
}

export default Bnpl
